import TranslationsConfigType from './translationType';

const nnTranslations: TranslationsConfigType = {
    tryAgain: 'Prøv om att',
    send: 'Send',
    buttonTextOk: 'Ok',
    month: 'månad',
    months: 'månader',
    avdragsfrihet: {
        title: 'Vil du søkje om avdragsfritak?',
        description:
            'Med avdragsfritak betalar du kun renter og omkostninger på lånet i ein periode. Det betyr at lånet ikkje minsker, sidan du utsetjer sjølve nedbetalinga av lånet, men at du får lågare månadlege kostnader i ein kortare periode.',
        feeWarning: 'Ver oppmerksam på at det kan tilkomme gebyr ved avdragsfritak.',
        feeWarningLinkText: 'Sjå meir i vår prisliste',
        promptDescription:
            'Hvis du har mista inntekta eller har blitt permittert kan du få mellombels avdragsfritak. Send oss ein førespurnad så skal vi se på saken!',
        banner: {
            title: 'Søk om avdragsfritak på lånet ditt',
            description: 'Er du permitert eller har du mista inntekta? Da kan du søkje om avdragsfritak.',
            shortDescription: 'Er du permitert eller har du mista inntekta?',
            shortTitle: 'Avdragsfritak på lån',
            button: 'Søk om avdragsfritak',
        },
        form: {
            start: 'Kom i gong',
            previous: 'Førre',
            next: 'Neste',
            loanTitle: 'Kva lån ønskjer du avdragsfritak på?',
            durationTitle: 'Kor lenge treng du avdragsfritak?',
            durationShowMoreOptions: 'Sjå fleire tidsrom',
            durationHideMoreOptions: 'Vis færre tidsrom',
            reasonTitle: 'Kva er grunnen til at du vil søkje om avdragsfritak',
            successTitle: 'Søknaden vert sendt',
            successDescription: 'Du høyrer frå oss når saken er ferdig behandla.',
            reasonExplainedLabel: 'Beskriv kort kva det gjeld: ',
            reasonExplainedErrorTooLong: 'Meldinga di er for lang. Du kan ikkje ha meir enn {} tegn.',
            reasonExplainedErrorTooShort: 'Denne beskrivinga vart for kort',
            creditCheck: 'Det vil bli satt i verk ei kredittvurdering av deg når du sender inn søknaden',
            chooseAccountError: 'Du må velja eit lån før du kan gå vidare',
            chooseDurationError: 'Du må velja eit tidsrom før du kan gå vidare',
            chooseReasonError: 'Du må velja ein grunn før du kan gå vidare',
        },
        noLoansHeader: 'Du har ingen lån hos oss',
        noLoansText:
            'For å kunne søkje om avdragsfritak må du ha lån hos oss. Du kan søkje om boliglån, smålån, billån og lån til andre køyretøy på lånesidene våre.',
        noLoansRedirect: 'Gå til lån',
        loanError: 'Det skjedde noko gale ved henting av låna dine, så me viser berre nokre av dei',
    },
    meldInteresse: {
        contact: {
            privacyText:
                'Etter at vi har kontakta deg vert informasjonen du oppgjev her sletta etter gjeldande rutiner hos oss. Meir om ',
            linkText: 'korleis vi handterer personvern.',
            button: 'Kontakt meg',
        },
        footerText: 'Du kan også ringe oss på',
        form: {
            onskerTilbud: 'Ønskjer tilbod på andre forsikringar',
            other: 'Kva forsikringar ønskjer du?',
            address: 'Adresse',
            postalcode: 'Postnummer og -stad',
            errors: {
                required: {
                    address: 'Du må leggja inn adresse.',
                    postalcode: 'Du må leggja inn postkoden og poststad.',
                },
            },
        },
        confirmation: {
            headerWithCustomerFirstName: 'Takk, {}!',
            informationWithCustomerPhoneNumber: 'Vi ringjer deg på {} så snart ein av rådgivarane våre er ledige.',
            button: 'Ferdig',
        },
    },
    contact: {
        title: 'Ynskjer du rådgjeving?',
        description:
            'Ynskjer du rådgjeving innad bankens tenester kan du få det kostnadsfritt hos oss. Send førespurnad og vi kontaktar deg!',
        buttonText: 'Send førespurnad',
        requestSentTitle: 'Førespurnad er sendt',
        requestSentDescription: 'Du hører fra oss innen {}',
    },
    bedrift: {
        title: 'Få betre oversyn med bedriftsmobilbank!',
        description:
            'Dersom du har ein konto som du brukar til ein bedrift eller en foreining kan bedriftsmobilbank vere eit godt alternativ. Send førespurnad og vi kontaktar deg!',
    },
    errors: {
        somethingWentWrong: 'Noko gjekk galt!',
        somethingWentWrongFetchingReceivers: 'Noko gjekk galt ved henting av mottakarane dine!',
        somethingWentWrongDeletingReceiver: 'Orsak, vi klarte ikkje å sletta mottakaren',
        somethingWentWrongChangingReceiver: 'Orsak, vi klarte ikkje å endra mottakaren',
        ohNo: 'Ikkje bra!',
        oops: 'Uff då!',
        timeoutMessage: 'Orsak, dette tok noko lengre tid enn vi hadde tenkt. Du må gjerne prøve om att',
        defaultMessage: 'Orsak, det oppstod ein teknisk feil. Ver vennleg og prøv igjen om ei tid.',
        badRequestMessage:
            'Det skjedde ein feil ved innsending av skjema. Er du sikker på at du har fylt ut skjemaet rett?',
    },
    createRequest: 'Kom i gang',
    copyErrorCode: 'Kopier feilmeldingskode',
    commonForm: {
        name: 'Namn',
        firstName: 'Fornamn',
        surname: 'Etternamn',
        phone: 'Telefonnummer',
        email: 'E-postadresse',
        errors: {
            required: {
                name: 'Du må leggja inn namn',
                firstName: 'Du må leggja inn fornamn.',
                surname: 'Du må leggja inn etternamn.',
                phone: 'Du må leggja inn telefonnummer.',
                email: 'Du må leggja inn e-postadresse.',
            },
            validation: {
                firstName: 'Fornamnet kan berre være {} tekn',
                surname: 'Etternamnet kan berre være {} tekn',
                phone: 'Telefonnummeret er ikkje gyldig.',
                email: 'E-postadressa er ikkje gyldig.',
            },
        },
    },
    contactForm: {
        contactUs: 'Kontakt oss',
        businessName: 'Bedriftsnamn',
        orgNr: 'Organisasjonsnummer',
        businessInfo: 'Firmaopplysningar',
        contactPerson: 'Kontaktperson',
        purposeOfContact: 'Kva ynskjer du å snakke med oss om?',
        purposeOfContactInfoMessage:
            'Av omsyn til ditt personvern ber vi deg om å ikkje leggje inn opplysningar som fødselsnummer eller annan sensitiv informasjon i meldinga.',
        contactDeletionRoutines:
            'Etter at vi har kontaktet deg blir informasjonen du oppgir her slettet etter gjeldende rutinar hos oss. Mer om {}',
        contactDeletionGDPRLinkText: 'korleis vi handterer personvern',
        sendForm: 'Send skjema',
        successMessage: 'Skjema er mottatt, vi kontaktar deg så raskt vi kan',
        errors: {
            required: { businessName: 'Du må leggja i bedriftsnamn på {} eller fleire tekn' },
        },
    },
    acquaintanceForm: {
        keepYouUpToDate: 'Vil du vi skal helde deg oppdatert?',
        fillOutForm: 'Da er det bare å fylle inn litt informasjon så hører du plutseleg fra oss!',
        fillOutProductForm:
            'Da er det bare å fylle inn litt informasjon og vel produkt du er interessert i så hører du fra oss!',
        speciallyImportantToYouAboutNewBank: 'Kva er særskilt viktig for deg når du skal velje ny bank?',
        reasonForNewBank: 'Kva er grunnen til at du er på utkikk etter ny bank?',
        bankUsage: 'Kva bruker du banken din til i dag?',
        bankUsages: {
            BILLS: 'Eg betaler rekningar og bruker kortet mitt',
            'MOBILE-PAY': 'Jeg betaler med mobilen',
            'FAMILY-EXPENSES': 'Eg har felleskonto for familiens utgifter',
            'FAMILY-ACCOUNT-OVERVIEW': 'Eg har oversyn over kontoane til familien min',
            'SAVINGS-ACCOUNT': 'Eg har sparekonto',
            'HOUSE-LOAN': 'Eg har bustadslån',
            'CAR-LOAN': 'Eg har billån',
            'OTHER-LOANS': 'Eg har andre lån',
            'CREDIT-CARD': 'Eg har kredittkort i banken min',
            'SHARES-AND-FUNDS': 'Eg sparer i aksjar og fond',
            ADVISOR: 'Eg bruker banken som rådgjevar',
            INSURANCE: 'Eg har forsikring i banken',
        },
        prodcutUsage: 'Kva produkt er du interessert i?',
        gdprMessage:
            'Så hyggjeleg at du vil vite meir om oss! Når du sendar inn dette skjemaet gjer du oss samtykke til å behandle opplysingane dine. Vi vil kun bruke kontaktinformasjonen din til å halde deg oppdatert om hva som skjer i {}. {}',
        bankName: 'Penni',
        moreAboutGdprHere: 'Les meir om korleis vi handterer personvern her.',
        otherProduct: 'Beskriv eit anna produkt her',
        errors: {
            validation: {
                bankUsageList: 'Du må velje ein ting du bruker banken til!',
                productList: 'Du må velje eit produkt i listen over!',
            },
        },
    },
};

export default nnTranslations;
